





















import { Vue, Component, Prop } from "vue-property-decorator";
import DataTableCellMobile from "@/components/data-table/DataTableCellMobile.vue";
import DataTableCellDesktop from "@/components/data-table/DataTableCellDesktop.vue";
import { ITableHeader } from "@/components/data-table/types";
import { NormalizedScopedSlot } from "vue/types/vnode";

@Component({
  components: {
    DataTableCellMobile,
    DataTableCellDesktop,
  },
})
export default class DataTableRow<T> extends Vue {
  @Prop({ required: true, type: Object }) item!: T;
  @Prop({ required: true, type: Array }) headers!: ITableHeader[];
  @Prop({ required: true, type: Boolean }) isMobile!: boolean;
  @Prop({ default: false, type: Boolean }) isHighlighted!: boolean;
  @Prop({ default: () => [], type: Array }) slots!: NormalizedScopedSlot[];

  get cellComponentType(): string {
    if (this.isMobile) {
      return "DataTableCellMobile";
    }

    return "DataTableCellDesktop";
  }
}
