









































import { Vue, Component, Prop } from "vue-property-decorator";
import { debounce } from "lodash";

type LocalValue = [string, string];

@Component
export default class RangeFilter extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ required: true }) label!: string;
  @Prop({ required: true }) isMobile!: boolean;
  @Prop({ default: () => ["0", "0"] }) value!: LocalValue;

  debounceEmitChange = debounce(
    (leftValue: string, rightValue: string) =>
      this.handleFilterChange(leftValue, rightValue),
    200
  );

  filterChanged(leftValue: string, rightValue: string): void {
    this.debounceEmitChange(leftValue, rightValue);
  }

  handleFilterChange(leftValue: string, rightValue: string): void {
    let valueToEmit: undefined | [string, string];

    if (
      !this.isFilterEmpty(leftValue, rightValue) &&
      !this.isValueEmpty(leftValue) &&
      !this.isValueEmpty(rightValue)
    ) {
      valueToEmit = [leftValue, rightValue];
    }

    this.$emit("filterChanged", {
      name: this.name,
      value: valueToEmit,
    });
  }

  isFilterEmpty(leftValue: string, rightValue: string) {
    return leftValue === "0" && rightValue === "0";
  }

  isValueEmpty(value: string) {
    return value === "";
  }

  get isUsed(): boolean {
    return this.value[0] > "0" || this.value[1] > "0";
  }
}
