
























import { Vue, Component, Prop } from "vue-property-decorator";

type LocalValue = any | any[];

@Component
export default class SelectFilter extends Vue {
  @Prop({ required: true }) items!: any[];
  @Prop({ required: true }) name!: string;
  @Prop({ required: true }) label!: string;
  @Prop({ default: false }) multiselect!: boolean;
  @Prop() value!: LocalValue;
  @Prop() itemText!: string;
  @Prop() itemValue!: string;

  searchInput = "";

  valueChanged(value: any | any[]): void {
    const valueToEmit =
      Array.isArray(value) && value.length === 0 ? undefined : value;
    this.$emit("filterChanged", {
      name: this.name,
      value: valueToEmit,
    });
  }

  get isUsed(): boolean {
    return this.value !== undefined;
  }

  reset(): void {
    this.$emit("filterChanged", {
      name: this.name,
      value: undefined,
    });
  }
}
