













import { Vue, Component, Prop } from "vue-property-decorator";
import { ITableHeader } from "@/components/data-table/types";
import { get } from "lodash";

@Component
export default class DataTableCellMobile<T> extends Vue {
  @Prop({ required: true, type: Object }) item!: T;
  @Prop({ required: true, type: Object }) header!: ITableHeader;

  get value() {
    return get(this.item, this.header.value);
  }
}
