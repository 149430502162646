

























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DataTableRow from "@/components/data-table/DataTableRow.vue";
import HeaderFilter from "@/components/data-table/HeaderFilter.vue";
import Filters from "@/components/data-table/Filters.vue";
import {
  ITableHeader,
  TableQuery,
  ActiveSortValues,
  FilterBy,
} from "@/components/data-table/types";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/constants";

interface TableOptions {
  page: number;
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
}

@Component({
  components: {
    DataTableRow,
    HeaderFilter,
    Filters,
  },
})
export default class DataTable<T> extends Vue {
  @Prop({ required: true, type: String }) name!: string;
  @Prop({ default: false, type: Boolean }) hasAddButton!: boolean;
  @Prop({ default: "", type: String }) addButtonText!: string;
  @Prop({ default: () => [], type: Array }) headers!: ITableHeader[];
  @Prop({ default: () => [], type: Array }) items!: T[];
  @Prop({ default: false, type: Boolean }) loading!: boolean;
  @Prop({ required: true, type: Number }) itemsLength!: number;
  @Prop({ default: 1, type: Number }) page!: number;
  @Prop({ default: 10, type: Number }) itemsPerPage!: number;
  @Prop({ default: false, type: Boolean }) clickable!: boolean;
  @Prop({ default: null, type: Object }) initialOptions!: TableOptions;
  @Prop({ type: Function }) highlightRow!: (item: T) => boolean;
  @Prop({ default: () => DEFAULT_ITEMS_PER_PAGE_OPTIONS, type: Array })
  itemsPerPageOptions!: any[];

  selectedHeaders: string[] = [];
  options: TableOptions = {
    page: this.page,
    itemsPerPage: this.itemsPerPage,
    sortBy: [],
    sortDesc: [],
  };
  filterBy: any = {};

  isRowHighlighted(item: T): boolean {
    if (typeof this.highlightRow !== "function") {
      return false;
    }

    return !!this.highlightRow(item);
  }

  get getAddButtonText() {
    if (this.addButtonText) {
      return this.addButtonText;
    }

    return this.$tc("newItem");
  }

  getParsedOptions(): TableQuery {
    return {
      page: this.options.page,
      itemsPerPage: this.options.itemsPerPage,
      filterBy: this.activeFilterBy,
      sortBy: this.activeSortValues,
    };
  }

  onRowClick(row: T) {
    if (this.clickable) {
      this.$emit("rowClicked", row);
    }
  }

  saveToLocalStorage(): void {
    window.localStorage.setItem(
      `tableOptions.${this.name}`,
      JSON.stringify({
        ...this.options,
        filterBy: this.filterBy,
        selectedHeaders: this.selectedHeaders,
      })
    );
  }

  emitChange(): void {
    this.saveToLocalStorage();
    this.$emit("optionsChanged", this.getParsedOptions());
  }

  onFilterChange(filters: FilterBy): void {
    // Updating page to 1 triggers onPageChanged which then emits whole filter change
    // However if page was already one onPageChanged will not be triggered to emitChange needs to be called here
    const shouldEmit = this.options.page === 1;
    this.filterBy = filters;
    this.options.page = 1;

    if (shouldEmit) {
      this.emitChange();
    }
  }

  onHeadersChange(headers: string[]): void {
    this.selectedHeaders = headers;
    this.saveToLocalStorage();
    const filters: FilterBy = {};
    this.filteredHeaders.forEach((header) => {
      const filterKey = header.filterValue || header.value;
      if (this.filterBy[filterKey] !== undefined) {
        filters[filterKey] = this.filterBy[filterKey];
      }
    });
    this.onFilterChange(filters);
  }

  onOptionsChanged(options: any): void {
    this.options = {
      page: options.page,
      itemsPerPage: options.itemsPerPage,
      sortBy: options.sortBy,
      sortDesc: options.sortDesc,
    };
    this.emitChange();
  }

  /*
      Ascending: false
      Descending: true
     */
  get activeSortValues(): ActiveSortValues {
    const sorts: ActiveSortValues = {};
    this.options.sortBy.forEach((key, index) => {
      sorts[key] = this.options.sortDesc[index] || false;
    });
    return sorts;
  }

  get activeFilterBy(): FilterBy {
    const activeFilters = Object.keys(this.filterBy);
    const filters: FilterBy = {};
    activeFilters.forEach((filter) => {
      if (this.filterBy[filter] !== undefined) {
        filters[filter] = this.filterBy[filter].toString();
      }
    });
    return filters;
  }

  get filteredHeaders(): ITableHeader[] {
    return this.headers.filter((header) => {
      return this.selectedHeaders.includes(header.value);
    });
  }

  @Watch("itemsLength")
  onItemsLengthChange(itemsLength: number): void {
    const maxPages = Math.ceil(itemsLength / this.options.itemsPerPage);
    if (this.options.page > maxPages) {
      this.options.page = 1;
    }
  }

  get itemSlots() {
    if (!this.$scopedSlots) {
      return [];
    }

    const slotEntries = Object.entries(this.$scopedSlots);

    return slotEntries.filter((slot) => {
      const slotName = slot[0];
      const slotContent = slot[1];
      return slotName.search("item.") === 0 && slotContent !== undefined;
    });
  }

  // windowResizeHandler() {
  //   const bottomScrollWrapper = this.bottomScrollWrapper;
  //   const topScrollWrapper = this.topScrollWrapper;
  //   if (bottomScrollWrapper.scrollWidth <= topScrollWrapper.clientWidth) {
  //     topScrollWrapper.style.display = `none`;
  //   } else {
  //     topScrollWrapper.style.display = `inherit`;
  //   }
  // }

  created(): void {
    this.selectedHeaders = this.headers.map((header) => header.value);

    if (this.initialOptions) {
      this.options = this.initialOptions;
    } else {
      const optionsInLocalStorage = window.localStorage.getItem(
        `tableOptions.${this.name}`
      );

      if (optionsInLocalStorage) {
        const { filterBy, selectedHeaders, ...options } = JSON.parse(
          optionsInLocalStorage
        );
        this.filterBy = filterBy;
        this.options = options;
        this.selectedHeaders = selectedHeaders;
      }
    }
    // window.addEventListener("resize", this.windowResizeHandler);
  }

  // mounted() {
  //   // top scroll is disabled currently
  //   const topScroll = this.topScroll;
  //   const topScrollWrapper = this.topScrollWrapper;
  //   const bottomScrollWrapper = this.bottomScrollWrapper;
  //   topScroll.style.width = `${bottomScrollWrapper.scrollWidth * 1.05}px`;
  //   if (bottomScrollWrapper.scrollWidth <= bottomScrollWrapper.clientWidth) {
  //     topScrollWrapper.style.display = `none`;
  //   }
  //   bottomScrollWrapper.onscroll = function () {
  //     topScrollWrapper.scrollLeft = bottomScrollWrapper.scrollLeft;
  //   };
  //   topScrollWrapper.onscroll = function () {
  //     bottomScrollWrapper.scrollLeft = topScrollWrapper.scrollLeft;
  //   };
  // }

  // get topScroll(): HTMLElement {
  //   return document.querySelector("#top-scroll") as HTMLElement;
  // }

  // get topScrollWrapper(): HTMLElement {
  //   return document.querySelector("#top-scroll-wrapper") as HTMLElement;
  // }

  get bottomScrollWrapper(): HTMLElement {
    return document.querySelector(
      ".bt-data-table .v-data-table__wrapper"
    ) as HTMLElement;
  }

  get screenHeight() {
    return window.innerHeight * 0.7;
  }

  // destroyed() {
  //   window.removeEventListener("resize", this.windowResizeHandler);
  // }
}
