




























import { Vue, Component, Prop } from "vue-property-decorator";
import {
  FilterBy,
  FilterTypeComponent,
  IFilterChange,
  ITableHeader,
} from "@/components/data-table/types";
import TextFilter from "@/components/data-table/filters/TextFilter.vue";
import RangeFilter from "@/components/data-table/filters/RangeFilter.vue";
import DateFilter from "@/components/data-table/filters/DateFilter.vue";
import SelectFilter from "@/components/data-table/filters/SelectFilter.vue";
import SelectAllCheckbox from "@/components/data-table/filters/SelectAllCheckbox.vue";
import { cloneDeep } from "lodash";

@Component({
  components: {
    TextFilter,
    RangeFilter,
    DateFilter,
    SelectFilter,
    SelectAllCheckbox,
  },
})
export default class Filters extends Vue {
  @Prop({ default: "", type: String }) tableName!: string;
  @Prop({ default: false, type: Boolean }) isMobile!: boolean;
  @Prop({ default: () => [], type: Array }) filteredHeaders!: ITableHeader[];
  @Prop({ default: () => ({}), type: Object }) value!: FilterBy;

  filterTypes = FilterTypeComponent;

  handleFilterChanged(payload: IFilterChange): void {
    const filterBy = cloneDeep(this.value);
    this.$set(filterBy, payload.name, payload.value);
    this.$emit("change", filterBy);
  }
}
