





































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import { DATE_FORMAT } from "@/constants";

type LocalValue = [string, string] | [string] | [];

@Component
export default class DateFilter extends Vue {
  @Prop({ required: true }) label!: string;
  @Prop({ required: true }) name!: string;
  @Prop({ default: () => [] }) value!: string | string[];

  dateFormat = "YYYY-MM-DD";

  get readableFormat(): string {
    if (Array.isArray(this.value)) {
      this.value.map((v) => moment(v).format(DATE_FORMAT)).toString();
    }

    if (typeof this.value === "string") {
      return this.value
        .split(",")
        .map((v) => moment(v).format(DATE_FORMAT))
        .toString();
    }

    return "";
  }

  isMenuOpened = false;
  pickerValue: LocalValue = [];
  localValue: LocalValue = [];

  @Watch("localValue", { deep: true })
  onFilterChanged(value: LocalValue): void {
    this.$emit("filterChanged", {
      name: this.name,
      value: this.cleanUpValue(value),
    });
  }

  cleanUpValue(value: LocalValue): string | undefined {
    if (value.length === 1) {
      // TODO: should be [this.localValue[0], this.localValue[0]]
      // this is just a dirty fix for now
      return [
        moment(value[0]).format(this.dateFormat),
        moment(value[0]).add(1, "d").format(this.dateFormat),
      ].toString();
    }

    if (value.length === 2) {
      /**
       * Messy sollution for now but using sort caused infinite loop
       */
      const momentA = moment(value[0]);
      const momentB = moment(value[1]);
      const isABeforeB = momentA.isBefore(momentB);
      const finalOrder = [];

      if (isABeforeB) {
        finalOrder[0] = momentA.format(this.dateFormat);
        finalOrder[1] = momentB.format(this.dateFormat);
      } else {
        finalOrder[0] = momentB.format(this.dateFormat);
        finalOrder[1] = momentA.format(this.dateFormat);
      }

      return finalOrder.toString();
    }

    return undefined;
  }

  get isUsed(): boolean {
    return this.value.length > 0;
  }

  reset(): void {
    this.$emit("filterChanged", {
      name: this.name,
      value: undefined,
    });
  }
}
