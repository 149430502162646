


















import { Vue, Component, Prop } from "vue-property-decorator";
import { debounce } from "lodash";

@Component
export default class TextFilter extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ required: true }) label!: string;
  @Prop({ default: "" }) value!: string;

  debounceEmitChange = debounce(this.handleFilterChange, 200);

  handleFilterChange(value: string): void {
    this.$emit("filterChanged", {
      name: this.name,
      value: value !== "" ? value : undefined,
    });
  }

  filterChanged(value: string): void {
    this.debounceEmitChange(value);
  }

  get isUsed(): boolean {
    return this.value.length > 0;
  }

  reset(): void {
    this.$emit("filterChanged", {
      name: this.name,
      value: undefined,
    });
  }
}
