










































import { Component, Vue, Prop } from "vue-property-decorator";

interface Header {
  text: string;
  value: string;
}
@Component
export default class HeaderFilter extends Vue {
  @Prop({ required: true, type: String }) name!: string;
  @Prop({ required: true, type: Array }) headers!: Header[];
  @Prop({ default: () => [], type: Array }) selectedHeaders!: string[];

  handleFilterChange(columns: string[]): void {
    this.$emit("change", columns);
  }

  get activatorButtonTitle(): string {
    return this.$t("dataTable.headerFilter.numberOfSelectedItems", {
      in: this.selectedHeaders.length.toString(),
      out: this.headers.length,
    }) as string;
  }
}
