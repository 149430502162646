import { DataTableHeader } from "vuetify";

export interface ITableHeader extends DataTableHeader {
  filterType?: IFilterType;
  multiselect?: boolean;
  options?: any[];
  optionText?: string;
  optionValue?: string;
  filterValue?: string;
}

export type IFilterType = "text" | "range" | "date" | "select" | "selectAll";

export interface IFilterChange {
  name: string;
  value: string;
}

export interface ActiveSortValues {
  [key: string]: boolean;
}

export interface TableQuery {
  page?: number;
  itemsPerPage?: number;
  sortBy?: SortBy;
  filterBy?: FilterBy;
}

export interface FilterBy {
  [key: string]: any;
}

export interface SortBy {
  [key: string]: boolean;
}

export enum FilterTypeComponent {
  text = "TextFilter",
  range = "RangeFilter",
  date = "DateFilter",
  select = "SelectFilter",
  selectAll = "SelectAllCheckbox",
}
